import React from "react";
import styled from "styled-components";
import youtube from "../assets/images/youtube.svg";
import naver from "../assets/images/naver.svg";
import facebook from "../assets/images/facebook.svg";
import { ReactComponent as Logo } from "../assets/images/company_logo.svg";

const Wrapper = styled.div`
  height: 324px;
  width: 100%;
  background-color: #fff;
`;

const FooterWrapper = styled.footer`
  max-width: 1200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 20px;
`;

const CompanyLogo = styled(Logo)`
  margin-bottom: 6px;

  @media only screen and (max-width: 600px) {
    width: 110px;
    height: 18px;
  }
`;

const Border = styled.div`
  border-bottom: 2px solid #ebebeb;
  margin: 18px 0;
`;

const FooterContents = styled.div`
  margin-top: 6px;
  margin-right: 10px;
  font-size: 14px;
  line-height: 20px;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 17px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin-top: 18px;
  }
`;

const IconLink = styled.a`
  cursor: pointer;
  &:nth-child(2) {
    margin: 0 4px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Footer = () => {
  const goToService = () => {
    window.open("https://adm.crossteam.co.kr/policy/service");
  };

  const goToPrivacy = () => {
    window.open("https://adm.crossteam.co.kr/policy/privacy");
  };

  return (
    <Wrapper>
      <FooterWrapper>
        <CompanyLogo></CompanyLogo>

        <div>
          <FooterContents>
            상호 : 크로스빔(주) | TEL : 02-2038-3014 | support @crossteam.co.kr
          </FooterContents>
          <FooterContents>
            주소 : 세종특별자치시 조치원읍 군청로 93, 세종SB플라자 3층 I-17 |
            우: 30033
          </FooterContents>
        </div>

        <Border></Border>

        <InfoWrapper>
          <div>
            <FooterContents>
              2021 CROSSBIM Inc. All rights reserved.
            </FooterContents>
            <ContentWrapper>
              <FooterContents onClick={goToPrivacy}>
                개인정보처리방침
              </FooterContents>
              <FooterContents onClick={goToService}>이용약관</FooterContents>
            </ContentWrapper>
          </div>

          <IconsWrapper>
            <IconLink
              href="https://www.youtube.com/channel/UCYT38wvy1wcPjvavidrxxsw"
              target="_blank"
            >
              <img src={youtube} alt="youtube"></img>
            </IconLink>
            <IconLink href="https://blog.naver.com/crossbim001" target="_blank">
              <img src={naver} alt="naver"></img>
            </IconLink>
            <IconLink
              href="https://www.facebook.com/crossteam911"
              target="_blank"
            >
              <img src={facebook} alt="facebook"></img>
            </IconLink>
          </IconsWrapper>
        </InfoWrapper>
      </FooterWrapper>
    </Wrapper>
  );
};

export default Footer;
