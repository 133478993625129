import React, { useState } from "react";
import styled, { css } from "styled-components";
import large from "../assets/images/main_large.png";
import medium from "../assets/images/main_medium.png";
import small from "../assets/images/main_small.png";

import apple from "../assets/images/apple.svg";
import google from "../assets/images/google.svg";

const Wrapper = styled.div`
  margin-top: 60px;
  height: 666px;
  width: 100%;
  background-color: #fff;

  @media only screen and (max-width: 600px) {
    height: 600px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    height: 746px;
  }
`;

const MainContents = styled.section`
  max-width: 1200px;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: unset;
  }
`;

const ContentsTextWrapper = styled.div`
  transform: translateY(-50%);

  @media only screen and (max-width: 600px) {
    margin-top: 34px;
    margin-bottom: 20px;

    transform: unset;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin: 30px 0;
    transform: unset;
  }
`;

const ContentsText = styled.div`
  font-size: 20px;
  line-height: 29px;
  color: #333333;

  @media only screen and (max-width: 600px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    text-align: center;
  }
`;

const StrongText = styled(ContentsText)`
  font-weight: bold;
  display: inline-block;
`;

const CompanyText = styled.div`
  font-weight: bold;
  color: #55c58b;
  font-size: 52px;
  line-height: 75px;
  letter-spacing: 0.02em;

  @media only screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 46px;
    text-align: center;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 44px;
    line-height: 64px;
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
  top: 140px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Button = styled.button`
  border-radius: 4px;
  width: 166px;
  height: 54px;
  border: 1px solid #55c58b;
  background-color: #fff;
  color: #55c58b;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: #55c58b;
  }

  ${(props) =>
    props.primary === "true" &&
    css`
      color: #fff;
      background-color: #55c58b;
    `}
  &:first-child {
    margin-right: 12px;
  }

  @media only screen and (max-width: 600px) {
    width: 130px;
    height: 40px;
    font-size: 12px;
    line-height: 23px;

    &:first-child {
      margin-right: 0px;
      margin-bottom: 12px;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 12px;
    }
  }
`;

const Image = styled.img`
  max-width: 662px;
  max-height: 466px;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media only screen and (max-width: 600px) {
    max-width: 460px;
    max-height: 340px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    max-width: 460px;
    max-height: 340px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
`;

const ToggleBackgroundCover = styled.div`
  z-index: 200;
  position: fixed;
  top: 0;
  background: #000;
  opacity: 0.2;
  width: 100%;
  height: 100vh;
`;

const ToggleAppDownLoad = styled.div`
  z-index: 300;
  position: fixed;
  bottom: 0;
  background: #fff;
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 56px;

  min-width: 320px;

  @media only screen and (max-width: 600px) {
    padding-top: 0;
  }
`;

const Text = styled.span`
  font-weight: bold;
  color: #333;
  font-size: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const PrimaryText = styled.span`
  font-weight: bold;
  color: #55c58b;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
  }
`;

const DownloadButonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 56px;

  @media only screen and (max-width: 600px) {
    margin-bottom: 0;
    margin-top: 48px;
  }
`;

const Link = styled.a`
  margin-right: 8px;
  @media only screen and (max-width: 600px) {
    &:first-child {
      margin-bottom: 14px;
    }
  }
`;

const DownloadButtonContainer = styled.div`
  display: flex;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const Main = () => {
  const [visible, setVisible] = useState(false);

  const goToJoin = () => {
    window.open("https://adm.crossteam.co.kr/join");
  };

  return (
    <>
      <Wrapper>
        <MainContents>
          <ContentsTextWrapper>
            <ContentsText>클라우드 기반</ContentsText>

            <ContentsText>
              <StrongText>건설 프로젝트 관리 </StrongText>
              &nbsp;협업툴
            </ContentsText>

            <CompanyText>크로스팀</CompanyText>

            <ButtonWrapper>
              <Button primary="true" onClick={goToJoin}>
                무료로 시작하기
              </Button>
              <Button onClick={() => setVisible(true)}>앱 다운로드</Button>
            </ButtonWrapper>
          </ContentsTextWrapper>

          <picture>
            <source srcSet={large} media="(min-width: 901px)" />
            <source
              srcSet={medium}
              media="(min-width: 601px) and (max-width: 900px)"
            />
            <source srcSet={small} media="(max-width: 600px)" />
            <Image src={large} alt="이미지" />
          </picture>
        </MainContents>
      </Wrapper>

      {visible && (
        <ToggleBackgroundCover
          onClick={() => setVisible(false)}
        ></ToggleBackgroundCover>
      )}
      {visible && (
        <ToggleAppDownLoad>
          <div>
            <Text>
              지금 바로&nbsp;
              <PrimaryText>크로스팀</PrimaryText>을 만나보세요.
            </Text>
          </div>

          <DownloadButonWrapper>
            <DownloadButtonContainer>
              <Link href="https://play.google.com/store/apps/details?id=com.crossbim.mobileviewer">
                <img src={google} alt="google" />
              </Link>
              <Link href="https://apps.apple.com/kr/app/%ED%81%AC%EB%A1%9C%EC%8A%A4%ED%8C%80/id1497089555">
                <img src={apple} alt="apple" />
              </Link>
            </DownloadButtonContainer>
          </DownloadButonWrapper>
        </ToggleAppDownLoad>
      )}
    </>
  );
};

export default Main;
