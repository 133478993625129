import React from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import albumLarge from "../assets/images/album_large.png";
import albumMedium from "../assets/images/album_medium.png";
import albumSmall from "../assets/images/album_small.png";

import documentLarge from "../assets/images/document_large.png";
import documentMedium from "../assets/images/document_medium.png";
import documentSmall from "../assets/images/document_small.png";

import commentLarge from "../assets/images/comment_large.png";
import commentMedium from "../assets/images/comment_medium.png";
import commentSmall from "../assets/images/comment_small.png";

import inspectionLarge from "../assets/images/inspection_large.png";
import inspectionMedium from "../assets/images/inspection_medium.png";
import inspectionSmall from "../assets/images/inspection_small.png";

import driveLarge from "../assets/images/drive_large.png";
import driveMedium from "../assets/images/drive_medium.png";
import driveSmall from "../assets/images/drive_small.png";

import materialLarge from "../assets/images/material_large.png";
import materialMedium from "../assets/images/material_medium.png";
import materialSmall from "../assets/images/material_small.png";

import punchLarge from "../assets/images/punch_large.png";
import punchMedium from "../assets/images/punch_medium.png";
import punchSmall from "../assets/images/punch_small.png";

const Wrapper = styled.div`
  height: 666px;
  width: 100%;
  background: #f8f8f8;

  @media only screen and (max-width: 600px) {
    height: 566px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    height: 804px;
  }
`;

const MainContents = styled.section`
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding: 0 20px;
`;

const SliderWrapper = styled(Slider)`
  height: 100%;
  padding-bottom: 10px;

  .slick-list {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-track .slick-slide {
    > div {
      height: 100%;
    }
  }

  .slick-dots {
    bottom: 0px;
    padding-bottom: 40px;

    > li {
      margin: 0 3px;
    }

    > li.slick-active button:before {
      color: #55c58b;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  height: 100% !important;
  width: 100%;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const SectionTitle = styled.div`
  font-size: 40px;
  line-height: 56px;
  color: #1a202c;
  font-weight: bold;
  margin-bottom: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 31px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
    line-height: 39px;
    text-align: center;
    margin-top: 24px;

    margin-bottom: 24px;
  }
`;

const SectionSummary = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #4a5568;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  margin-left: 12px;
`;

const Image = styled.img`
  max-width: 662px;
  max-height: 454px;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
  backface-visibility: hidden;

  @media only screen and (max-width: 600px) {
    max-width: 460px;
    max-height: 280px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    max-width: 460px;
    max-height: 340px;
    width: 100%;

    image-rendering: -webkit-optimize-contrast;
    transform: translateZ(0);
    backface-visibility: hidden;
  }
`;

const ServiceMainContents = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    pauseOnHover: true,
    autoplay: true,
  };

  return (
    <Wrapper id="menu">
      <MainContents>
        <SliderWrapper {...settings}>
          <SectionWrapper>
            <div>
              <SectionTitle>
                현장 진행상황을 <br /> 손쉽게 파악
              </SectionTitle>
              <SectionSummary>
                사진과 영상 뿐만 아니라 360도 카메라까지
                <br />
                지원하여 관계자가 3차원 정보를 웹과 모바일 <br />
                앱을 통해 손쉽게 확인할 수 있습니다. 현장에
                <br />
                방문하지 않고도, 효율적으로 커뮤니케이션 <br />할 수 있습니다.
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={albumLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={albumMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={albumSmall} media="(max-width: 600px)" />
                  <Image src={albumLarge} alt="album" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                수많은 이슈를 <br />
                효율적으로 관리
              </SectionTitle>
              <SectionSummary>
                산발적으로 생겨나는 이슈들을 공종별, <br />
                상태별로 나누어 직관적으로 관리할 수 있습니다.
                <br />
                조직별로 관리가 가능하여 업무인수인계에도
                <br />
                효과적입니다.
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={commentLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={commentMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={commentSmall} media="(max-width: 600px)" />
                  <Image src={commentLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                수기작업의
                <br />
                디지털 혁명
              </SectionTitle>
              <SectionSummary>
                작업일보, 출역일보 등을 협력업체가 직접 작성할
                <br />
                수 있습니다. 웹사이트와 모바일 앱을 통해서 가<br />
                능하기 현장에서도 편리하게 작성 가능합니다. <br />
                업체별 정보는 관리자 화면에서 자동으로 취합되 <br />
                며, 전자결재도 가능합니다. 디지털 전환을 통해 <br />
                생산성 향상을 경험하세요.
              </SectionSummary>
            </div>

            <div>
              <div>
                <ImageWrapper>
                  <picture>
                    <source srcSet={documentLarge} media="(min-width: 901px)" />
                    <source
                      srcSet={documentMedium}
                      media="(min-width: 601px) and (max-width: 900px)"
                    />
                    <source srcSet={documentSmall} media="(max-width: 600px)" />
                    <Image src={documentLarge} alt="comments" />
                  </picture>
                </ImageWrapper>
              </div>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                손쉽게 검측하고
                <br />
                편리하게 관리
              </SectionTitle>
              <SectionSummary>
                검측문서를 모바일로 연동하여 감리와 함께 <br />
                손쉽게 작성,승인 할수 있습니다. 실명부는 <br />
                출역일보와 연동되어 정확하고 빠르게 작성할 <br />
                수 있습니다. 완료된 문서는 웹사이트에서 <br />
                공종별, 위치별로 편리하게 관리하세요.
              </SectionSummary>
            </div>

            <div>
              <div>
                <ImageWrapper>
                  <picture>
                    <source
                      srcSet={inspectionLarge}
                      media="(min-width: 901px)"
                    />
                    <source
                      srcSet={inspectionMedium}
                      media="(min-width: 601px) and (max-width: 900px)"
                    />
                    <source
                      srcSet={inspectionSmall}
                      media="(max-width: 600px)"
                    />
                    <Image src={inspectionLarge} alt="inspection" />
                  </picture>
                </ImageWrapper>
              </div>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                클라우드로 모든 파일을 <br />
                손쉽게 공유
              </SectionTitle>
              <SectionSummary>
                공유해야하는 캐드, 엑셀, 한글파일 등 모든 <br />
                종류의 자료를 드라이브에 올리면 모든 사람 <br />
                들이 항상 최신의 정보를 확인할 수 있습니다. <br />
                파일별 버전관리, 권한설정도 가능합니다. <br />
                PDF의 경우 웹사이트 뿐만 아니라 앱에서도 <br />
                바로 확인하여 편리합니다.
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={driveLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={driveMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={driveSmall} media="(max-width: 600px)" />
                  <Image src={driveLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                반입자재 검수요청서와 <br />
                자재수불부를 한번에
              </SectionTitle>
              <SectionSummary>
                반입자재 검수요청서를 모바일에서 작성하면
                <br />
                자재수불부에 자동으로 해당 정보가 기입됩니
                <br />
                다. 또한 주요자재에 대해서는 작업일보와 연 <br />
                동도 가능하니 한번의 정보 기입으로 3번 활 <br />
                용 할 수 있습니다. 데이터 연동으로 정확하고
                <br />
                편리하게 자재정보를 관리할 수 있습니다.
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={materialLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={materialMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={materialSmall} media="(max-width: 600px)" />
                  <Image src={materialLarge} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>

          <SectionWrapper>
            <div>
              <SectionTitle>
                펀치리스트로 <br />
                간편하고 깔끔한 마무리
              </SectionTitle>

              <SectionSummary>
                펀치리스트를 사용하면 준공시점 발생하는
                <br />
                수많은 수정요청들을 손쉽게 관리할 수 있
                <br />
                습니다. 협력업체 담당자들에게 직접 수정을 <br />
                요청하고, 푸시 알림까지 가기 때문에 더욱 <br />
                효과적입니다. 모바일에서 수정해야 할 사진 <br />
                부위를 표시하고, 도면 위치까지 표기할 수 <br />
                있어 더욱 직관적으로 사용할 수 있습니다.
              </SectionSummary>
            </div>

            <div>
              <ImageWrapper>
                <picture>
                  <source srcSet={punchLarge} media="(min-width: 901px)" />
                  <source
                    srcSet={punchMedium}
                    media="(min-width: 601px) and (max-width: 900px)"
                  />
                  <source srcSet={materialSmall} media="(max-width: 600px)" />
                  <Image src={punchSmall} alt="document" />
                </picture>
              </ImageWrapper>
            </div>
          </SectionWrapper>
        </SliderWrapper>
      </MainContents>
    </Wrapper>
  );
};

export default ServiceMainContents;
