import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 480px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #55c58b;

  @media only screen and (max-width: 900px) {
    height: 298px;
  }
`;

const Contents = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
`;

const ContentsTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    margin-bottom: 28px;
  }
`;

const ContentsTitle = styled.span`
  color: #fff;
  font-size: 40px;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 22px;
    line-height: 28px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 28px;
  }
`;

const ContentsParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  line-height: 38px;
  color: #fff;
  font-weight: 700;

  @media only screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 20px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ContentsParagraph = styled.p`
  text-align: center;
`;

const ServiceIntroduction = () => {
  return (
    <Wrapper id="introduction">
      <Contents>
        <ContentsTitleWrapper>
          <ContentsTitle>SAVE TIME,&nbsp;</ContentsTitle>
          <ContentsTitle>SAVE COST</ContentsTitle>
        </ContentsTitleWrapper>

        <ContentsParagraphWrapper>
          <ContentsParagraph>
            자료공유, 커뮤니케이션, 문서작성에서 관리까지
          </ContentsParagraph>
          <ContentsParagraph>
            다양한 사람들과의 협업은 성공적인 건설 프로젝트의 필수 요소입니다.
          </ContentsParagraph>
          <ContentsParagraph>
            24개월 현장에 적용할 경우, 현장기사 1명의 인건비를 절약할 수
            있습니다.
          </ContentsParagraph>
          <ContentsParagraph>
            크로스팀을 통해 구성원들과 안전하고 효율적인 협업을 경험해 보세요.
          </ContentsParagraph>
        </ContentsParagraphWrapper>
      </Contents>
    </Wrapper>
  );
};

export default ServiceIntroduction;
