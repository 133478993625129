import React, { useState } from "react";
import styled, { css } from "styled-components";
import { ReactComponent as Logo } from "../assets/images/company_logo.svg";
import { ReactComponent as HamburgerImage } from "../assets/images/bars-solid.svg";

const Wrapper = styled.div`
  width: 100%;
  background-color: #fbfbfb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  z-index: 100;
`;

const HeaderContainer = styled.header`
  height: 60px;
`;

const LogoLink = styled.a`
  height: 22px;
  @media only screen and (max-width: 900px) {
    margin-left: 20px;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
    align-items: flex-start;
    justify-content: center;
  }

  @media only screen and (min-width: 900px) {
    padding: 0 20px;
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
`;

const Lists = styled.ul`
  height: 100%;
  align-items: center;
  background-color: #fbfbfb;

  @media only screen and (max-width: 900px) {
    position: absolute;
    top: 60px;
    flex-direction: column;
    text-align: center;
    width: 100%;
    display: ${(props) => (props.visible ? "flex" : "none")};
  }

  @media only screen and (min-width: 900px) {
    display: flex;
  }
`;

const ListsItem = styled.li`
  color: #333333;
  cursor: pointer;
  line-height: 20px;
  margin-left: 60px;
  background-color: inherit;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-left: 0;
    padding: 18px 20px;

    &:not(:last-child) {
      &:hover {
        background-color: #eff0f1;
      }
    }
  }
`;

const Button = styled.button`
  width: 132px;
  height: 42px;
  color: #fff;
  background-color: #55c58b;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 40px;

  ${(props) =>
    props.list === "true" &&
    css`
      display: none;
      @media only screen and (max-width: 900px) {
        width: 70%;
        margin: 0 auto;
        display: block;
      }
    `}
  ${(props) =>
    props.header === "true" &&
    css`
      display: none;
      @media only screen and (min-width: 900px) {
        display: block;
      }
    `};
`;

const Hamburger = styled(HamburgerImage)`
  width: 22px;
  height: 22px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

const ToggleBackgroundCover = styled.div`
  z-index: 50;
  position: fixed;
  top: 0;
  background: #000;
  opacity: 0.1;
  width: 100%;
  height: 100vh;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Login = styled.span`
  color: #55c58b;
  cursor: pointer;

  ${(props) =>
    props.list === "true" &&
    css`
      display: none;
      @media only screen and (max-width: 900px) {
        width: 70%;
        margin: 0 auto;
        display: block;
      }
    `}

  ${(props) =>
    props.header === "true" &&
    css`
      display: none;
      @media only screen and (min-width: 900px) {
        display: block;
      }
    `};
`;

const Header = () => {
  const [visible, setVisible] = useState(false);

  const onClick = (e) => {
    const link = e.target.dataset.link;
    const scrollTo = document.querySelector(link);
    scrollTo.scrollIntoView();
  };

  const goToLoginPage = () => {
    window.open("https://adm.crossteam.co.kr");
  };

  const goToJoinPage = () => {
    window.open("https://adm.crossteam.co.kr/join");
  };

  return (
    <>
      <Wrapper>
        <HeaderContainer>
          <Nav>
            <NavItems>
              <LogoLink href="/">
                <Logo></Logo>
              </LogoLink>

              <Lists visible={visible}>
                <ListsItem onClick={onClick} data-link="#introduction">
                  서비스소개
                </ListsItem>
                <ListsItem
                  onClick={onClick}
                  data-link="#enterpriseIntroduction"
                >
                  도입사례
                </ListsItem>
                <ListsItem onClick={onClick} data-link="#menu">
                  주요메뉴
                </ListsItem>
                <ListsItem>
                  <Login list="true" onClick={goToLoginPage}>
                    로그인
                  </Login>
                </ListsItem>
                <ListsItem>
                  <Button list="true" onClick={goToJoinPage}>
                    무료로 시작하기
                  </Button>
                </ListsItem>
              </Lists>
            </NavItems>

            <ButtonContainer>
              <Login header="true" onClick={goToLoginPage}>
                로그인
              </Login>
              <Button header="true" onClick={goToJoinPage}>
                무료로 시작하기
              </Button>
            </ButtonContainer>

            <Hamburger onClick={() => setVisible(!visible)}></Hamburger>
          </Nav>
        </HeaderContainer>
      </Wrapper>

      {visible && (
        <ToggleBackgroundCover
          onClick={() => setVisible(false)}
        ></ToggleBackgroundCover>
      )}
    </>
  );
};

export default Header;
