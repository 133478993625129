import React from "react";
import styled from "styled-components";

import spaceDBM from "../assets/images/enterprise/spaceDBM.png";
import heeRim from "../assets/images/enterprise/heeRim.png";
import hoBan from "../assets/images/enterprise/hoBan.png";
import kukBo from "../assets/images/enterprise/kukBo.png";
import cj from "../assets/images/enterprise/CJ.png";
import bando from "../assets/images/enterprise/bando.png";

const Wrapper = styled.div`
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 900px) {
    height: 298px;
  }
`;

const SectionTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    margin-bottom: 12px;
  }
`;

const SectionTitle = styled.div`
  font-size: 24px;
  line-height: 56px;
  color: #1a202c;
  font-weight: bold;
  margin-bottom: 24px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 8px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    font-size: 18px;
    line-height: 39px;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const ImageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  place-items: center;

  @media only screen and (max-width: 600px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const Image = styled.img`
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  width: 240px;
  height: 80px;

  @media only screen and (max-width: 600px) {
    width: 150px;
    height: 40px;
  }

  @media only screen and (min-width: 601px) and (max-width: 900px) {
    width: 240px;
    height: 60px;
  }
`;

const EnterpriseIntroduction = () => {
  return (
    <Wrapper id="enterpriseIntroduction">
      <SectionTitleWrapper>
        <SectionTitle>인테리어에서부터 공동주택까지&nbsp;</SectionTitle>
        <SectionTitle>다양한 현장에서 적용되고 있습니다.</SectionTitle>
      </SectionTitleWrapper>

      <ImageContainer>
        <ImageWrapper>
          <Image src={spaceDBM} />
          <Image src={heeRim} />
          <Image src={hoBan} />
          <Image src={kukBo} />
          <Image src={cj} />
          <Image src={bando} />
        </ImageWrapper>
      </ImageContainer>
    </Wrapper>
  );
};

export default EnterpriseIntroduction;
